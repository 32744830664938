import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DesktopApp from './desktop/index';
import MobileApp from './mobile/App';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions';
import ScrollToTop from './ScrollToTop'; // Import the ScrollToTop component

const App = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Router>
      <ScrollToTop />
        <Routes>
          {/* Route for Privacy Policy */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          {/* Route for Terms and Conditions */}
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          {/* Default route for MobileApp or DesktopApp */}
          <Route path="/*" element={isMobile ? <MobileApp /> : <DesktopApp />} />
        </Routes>
    </Router>
  );
};

export default App;