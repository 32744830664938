import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import translation files
import en from '../static/locales/en.json';
import nl from '../static/locales/nl.json';

i18n
  .use(LanguageDetector) // Detect browser language
  .use(initReactI18next) // Integrate with React
  .init({
    resources: {
      en: { translation: en },
      nl: { translation: nl },
    },
    fallbackLng: 'en', // Fallback to English if language is not supported
    debug: false, // Disable debug mode for production
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });

export default i18n;