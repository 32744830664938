import React from "react";
import PageWrapper from "./PageWrapper";
import { useTranslation } from "react-i18next";

const TermsAndConditions = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper titleKey="Terms-and-conditions">
      <div className="content-section">
        <p>{t("terms.lastUpdated")}</p>
        <p>{t("terms.introduction")}</p>

        <h2>{t("terms.section1.title")}</h2>
        <p>{t("terms.section1.description")}</p>

        <h2>{t("terms.section2.title")}</h2>
        <p>{t("terms.section2.description")}</p>

        <h2>{t("terms.section3.title")}</h2>
        <p>{t("terms.section3.description")}</p>

        <h2>{t("terms.section4.title")}</h2>
        <p>{t("terms.section4.description")}</p>

        <h2>{t("terms.section5.title")}</h2>
        <p>
          {t("terms.section5.description")}{" "}
          <a href="/privacy-policy" className="privacy-policy-link">
            {t("Privacy Policy")}
          </a>
          .
        </p>

        <h2>{t("terms.section6.title")}</h2>
        <p>{t("terms.section6.description")}</p>

        <h2>{t("terms.section7.title")}</h2>
        <p>{t("terms.section7.description")}</p>

        <h2>{t("terms.section8.title")}</h2>
        <p>{t("terms.section8.description")}</p>

        <h2>{t("terms.section9.title")}</h2>
        <p>{t("terms.section9.description")}</p>

        <h2>{t("terms.disclaimer.title")}</h2>
        <p>{t("terms.disclaimer.description")}</p>

        <h2>{t("terms.userresponsibility.title")}</h2>
        <ul>
          <li>{t("terms.userresponsibility.dataverification")}</li>
          <li>{t("terms.userresponsibility.accuracyoffilings")}</li>
        </ul>

        <h2>{t("terms.limitation.title")}</h2>
        <p>{t("terms.limitation.description")}</p>

        <h2>{t("terms.contactTitle")}</h2>
        <p>
          {t("terms.contactDescription")}{" "}
          <a href="mailto:hello@summie.co" className="email-link">
            hello@summie.co
          </a>
          .
        </p>
      </div>
    </PageWrapper>
  );
};

export default TermsAndConditions;